<template>
  <div id="setting" class="content">
    <div class="box-show-title">
      <div class="container">
        <el-row :gutter="30">
          <el-col :span="24" :md="6">
            <div class="tab-setting">
              ตั้งค่าบัญชี
              <ul class="tab-site">
                <li>
                  <router-link to="/setting/myAccount">บัญชีของฉัน</router-link>
                </li>
                <li>
                  <router-link to="/setting/settingpassword"
                    >เปลี่ยนรหัสผ่านใหม่</router-link
                  >
                </li>
              </ul>
            </div>
          </el-col>
          <el-col :span="24" :md="18" class="detail-profile">
            <div class="grid-content">
              <el-row :gutter="30" class="setrow">
                <el-col :span="24" class="aboutus">
                  <p>เปลี่ยนรหัสผ่านใหม่</p>
                </el-col>
                <el-col :span="24" class="box-tab-l">
                  <el-form
                    :label-position="'top'"
                    :model="resetPssword"
                    status-icon
                    :rules="rules"
                    ref="resetPssword"
                    class="form-forgot"
                  >
                    <el-form-item label="รหัสผ่านปัจจุบัน" prop="oldPass">
                      <el-input
                        type="password"
                        v-model="resetPssword.oldPass"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="รหัสผ่านใหม่" prop="pass">
                      <el-input
                        type="password"
                        v-model="resetPssword.pass"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                    <el-form-item label="ยืนยันรหัสผ่านใหม่" prop="checkPass">
                      <el-input
                        type="password"
                        v-model="resetPssword.checkPass"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <div class="setbtn">
            <router-link to="/home" v-if="user == null || user.role != 50">
              <button
                class="btn btn-color-dark btn-cancle"
                @click="cancleForm('resetPssword')"
              >
                <span>ยกเลิก</span>
              </button>
            </router-link>
             <router-link to="/management" v-else>
              <button
                class="btn btn-colo
                r-dark btn-cancle"
                @click="cancleForm('resetPssword')"
              >
                <span>ยกเลิก</span>
              </button>
            </router-link>
            <button
              class="btn btn-color-dark btn-save"
              @click="submitForm('resetPssword')"
            >
              <span>บันทึก</span>
            </button>
          </div>
        </el-row>
      </div>
    </div>
  </div>
</template>
<style  scope="scoped">
.el-form-item__label {
  margin-top: 15px !important;
}
.detail-profile .box-tab-l {
  padding: 20px 15px 40px !important;
}
</style>
<script>
import { HTTP } from "@/service/axios.js";
export default {
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else if (value.length < 8) {
        callback(new Error("รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร"));
      } else {
        if (this.resetPssword.checkPass !== "") {
          this.$refs.resetPssword.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else if (value !== this.resetPssword.pass) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      resetPssword: {
        oldPass: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        oldPass: [
          {
            required: true,
            validator: validatePass,
            trigger: "blur",
          },
        ],
        pass: [
          {
            required: true,
            validator: validatePass,
            trigger: "blur",
          },
        ],
        checkPass: [
          {
            required: true,
            validator: validatePass2,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    cancleForm(formName) {
      this.$refs[formName].resetFields();
    },
    signOut() {
      this.$store.commit("SIGNOUT");
      this.$router.push(`/home`);
    },
    submitForm(formName) {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let obj = {
            password: this.resetPssword.oldPass,
            newPassword: this.resetPssword.checkPass,
          };
          HTTP.put(`change/password`, obj)
            .then((res) => {
              console.log(res);
              if (res.data.success) {
                this.signOut()
                this.$message({
                  message: "เปลี่ยนรหัสผ่านสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
              } else {
                this.$message({
                  message: "เปลี่ยนรหัสผ่านไม่สำเร็จ",
                  type: "error",
                  duration: 4000,
                });
                this.$refs[formName].resetFields();
              }
            })
            .catch((e) => {
              this.alertCatchError(e);
              console.log("เปลี่ยนรหัสผ่านไม่สำเร็จ");
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.btn-save {
  margin-top: 20px;
  width: 154px;
  margin-left: 15px;
}
.btn-cancle {
  margin-top: 20px;
  width: 154px;
  background: transparent;
  color: #7e828f;
  border: 1px solid #7e828f;
}
</style>